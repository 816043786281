// @TODO: Use tailwind theme instead

export default {
    colors: {
        background: "rgba(0,0,0,0.05)",
        main: '#D96262',
        secondary: '#2A3E59',
        trinary: '#A6786D',
        light: '#eae6e5',
        neutral: '#8f8073',
        selectedHeader: '#F27E63'
    }
}